import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TOSPage = () => (
  <Layout>
    <SEO title="Terms of Service" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
      <div>
        <h1>Terms of Service</h1>
        <div>
          <h1>1. Ownership of web site</h1>
          <p>1.1. The following notice and legal information (referred to as the “Terms &amp; Conditions”) regulates the use of the Agora Voting, S.L (referred to as “Agora Voting”) service offered through the website <a href="https://nvotes.com/">www.nvotes.com</a>&nbsp;(referred to as the “Website” or “Platform”).</p>
          <p>1.2. The Website, Platform and the technology are owned by Agora Voting with registered office at Guzman El Bueno, 11, 6-1 28015, Madrid, Spain with tax identification number B87138467 and registered in the Commercial Registry of Madrid, in book 41536, page 142.</p>
          <p>1.3 If you wish to communicate with the Company, you may contact Agora Voting at the following email address:&nbsp;<a href="mailto:nvotes@nvotes.com">nvotes@nvotes.com</a></p>
          <h2>2. Acceptance of the use conditions</h2>
          <p>2.1. The use of the Website or Platform by any person (referred to below as the “User”) implies full and unreserved acceptance of each and every one of the provisions included in this legal notice in the version published at the moment that the User accesses the Website. Consequently, the User must carefully read this legal notice on each of the occasions in which the User intends to use the Website, as it may be modified.</p>
          <p>2.2. Agora Voting reserves the right to periodically modify the conditions of use of the Website or Platform, so that the User is obliged to periodically review these terms.</p>
          <p>2.3. The Terms &amp; Conditions of use do not exclude the possibility that certain services on the Website, or their characteristics, may also be special conditions of use, which, in any case, may be consulted by the User before activation.</p>
          <h2>3. Objective</h2>
          <p>3.1. The Website and Platform aim to inform the User about the services offered by Agora Voting and, especially, about how to manage votes and ballots through the Agora Voting system and technology.</p>
          <p>3.2. Agora Voting may, depending on the development and evolution of its products and services, expand or modify its Website or Platform, including or substituting new services, activities, features or contents.</p>
          <p>3.3. The contracting of Agora Voting’s services shall be subject to the corresponding special conditions.</p>
          <h2>4.1. Condition, Quality and user concept</h2>
          <p>4.1.1. A User is intended to be any person of legal age to access the Website for any purpose.</p>
          <p>4.1.2. Access to the Website and Platform is for the sole purpose of consulting, searching or displaying information. No previous registration shall be required, expressed or accepted as a registered user of the Website or any personal information needed to be submitted. However, the use of certain services, such as hiring the Agora Voting services, participating in specific voting or subscribing to the newsletter, may be subject to prior registration. In these cases, the formal registration of the user or submission of personal data may be communicated through filling out the corresponding forms. It is imperative that the User authorizes freely, expressly and voluntarily the use of their provided personal information.</p>
          <h2>4.2. Diligent use of the website</h2>
          <p>4.2.1. The User acknowledges and agrees that use of the content and services offered by the Website will be at his or her own risk and responsibility.</p>
          <p>4.2.2 The User agrees to use the Website and the services offered by Agora Voting in accordance with the provisions of the Terms &amp; Conditions and to use the services and the content of this Website appropriately and not to use it to perform illegal or criminal activities, violate the rights of others or violate the intellectual property regulations, or any other applicable laws or regulations.</p>
          <p>4.2.3. The User undertakes not to introduce or spread a virus on the Website or Platform or its systems capable of causing harmful damage to Agora Voting’s computer systems and its Users, and not to introduce links to improve access to the site without express permission from the owner, in cases where authorization is needed.</p>
          <p>4.2.4. The User agrees and undertakes not to take any action to harm the reputation of Agora Voting or third parties.</p>
          <p>4.2.5. The User who accesses the Platform acknowledges that any data or information provided shall be real and updated, being responsible for any harm caused otherwise.</p>
          <h2>5. Disclaimer of liability</h2>
          <p>5.1. Proper operation and continuous availability</p>
          <p>Agora Voting is not liable to the User for the malfunction of the Website, nor for the interruption, delay, slowness, loss or disconnections of communications nor the transmission of messages. Agora Voting does not guarantee and is not responsible for the continuous, constant, and uninterrupted operation of the Website.</p>
          <p>5.2. Liability of the User</p>
          <p>Notwithstanding the effort Agora Voting has put to implement the highest technological quality standards, Agora Voting is not responsible, directly nor indirectly, for damages of any kind caused to the User as a result of the presence of viruses or other elements in the content and Website services that may alter the user’s computer system. Nor will it be liable for damages and losses caused by the User for access errors and mistakes in the access, in the connections, transmissions, or interruptions without just cause of the service, for intrusions, computer viruses, third party mass advertising mailings or where those causes are due to situations outside the site, such as defects in the user’s computer, deficiency in its connection, internal virus on the user’s computer, communication problem of the user’s phone operator, or when due to force majeure.</p>
          <p>5.3 Disclaimer of Liability for Third Party Links</p>
          <p>Agora Voting will not be liable, directly or indirectly, for damages of any kind caused to the User as a result of its connection to other pages and websites published on the Website as links, since it does not have the capacity to control or supervise the content of these external websites. The User accepts the full risk of any damages that may be suffered as a result of connecting to or using such websites.</p>
          <p>5.4. Third Party Liability</p>
          <p>5.4.1. Agora Voting is not liable for acts performed by third parties outside the system, which include violating the security measures, carrying out acts against users such as sending computer viruses, mass advertising or spam, commercial emails, interruption of service and access to messages.</p>
          <p>5.4.2. Agora Voting undertakes to adopt appropriate technical, organizational and legal security for communication as required by law. Notwithstanding the foregoing, Agora Voting cannot ensure or guarantee this and therefore does not have any liability associated with any breach of its security or communications by a third party in bad faith, which may entail severe cybernetic attacks towards the integrity, reliability, transparency or confidentiality of the content, data or arranged votes.</p>
          <p>5.4.3. Agora Voting is not liable for any damage caused by acts of God, or by inadequate performance of the Website or its services for reasons beyond its jurisdiction. This may include the following non-exhaustive list: malfunction of internet operators, incorrect user computer configuration or insufficient capacity of the computer system to support the content and extent of the information displayed on the Website.</p>
          <p>5.5. Agora Voting has taken the security measures according to the strictest safety standards, without prejudice that no measures are technologically inexpugnable, and therefore cannot be liable for any damages caused when acting with the dully prudence required.</p>
          <h2>6. Intellectual &amp; Industrial property</h2>
          <p>6.1. Agora Voting owns the intellectual and industrial property rights, or has obtained relevant permits or licenses for the use of its technology, domain name, trademarks and logos, application, information and other works and creations related to the Website and the technology associated with it, as well as its content.</p>
          <p>6.2. The contents of this Website, including designs, applications, text, images and source code (collectively referred to as the “Content”) are protected by intellectual and industrial property rights.</p>
          <p>6.3. In no way may this Content be used, reproduced, copied or transmitted in any form without prior written and explicit consent of Agora Voting.</p>
          <p>6.4. Agora Voting assigns a worldwide royalty free use license and the User acknowledges to properly use it according to the correct purpose of the Website and the Platform</p>
          <p>6.5. Agora Voting is a registered trademark whose rights belong exclusively to Agora Voting.</p>
          <h2>7. Privacy and confidentiality</h2>
          <p>All users agree to comply with the Privacy Policy of this Website in accordance with the provisions in the following link:&nbsp;<a href="https://nvotes.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
        </div>
      </div>

  </Layout>
)

export default TOSPage
